// automatically generated by the FlatBuffers compiler, do not modify

import { flatbuffers } from "./flatbuffers";
/**
 * @enum
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export enum AllMessages {
        NONE = 0,
        NetWorldView = 1,
        NetSpawn = 2,
        NetEvent = 3,
        NetControlInput = 4,
        NetPing = 5,
        NetLeaderboard = 6,
        NetExit = 7,
        NetAuthenticate = 8
    }
}

/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetLeaderboard {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetLeaderboard
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetLeaderboard {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetLeaderboard= obj
         * @returns NetLeaderboard
         */
        static getRootAsNetLeaderboard(bb: flatbuffers.ByteBuffer, obj?: NetLeaderboard): NetLeaderboard {
            return (obj || new NetLeaderboard()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        fleetID(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        type(): string | null;
        type(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        type(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param number index
         * @param Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry= obj
         * @returns Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry
         */
        entries(index: number, obj?: Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry): Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry | null {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        entriesLength(): number {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry= obj
         * @returns Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry|null
         */
        record(obj?: Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry): Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry | null {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.NetLeaderboardEntry()).__init(this.bb!.__indirect(this.bb_pos + offset), this.bb!) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        modeData(): string | null;
        modeData(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        modeData(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetLeaderboard(builder: flatbuffers.Builder) {
            builder.startObject(5);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number fleetID
         */
        static addFleetID(builder: flatbuffers.Builder, fleetID: number) {
            builder.addFieldInt32(0, fleetID, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset typeOffset
         */
        static addType(builder: flatbuffers.Builder, typeOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, typeOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset entriesOffset
         */
        static addEntries(builder: flatbuffers.Builder, entriesOffset: flatbuffers.Offset) {
            builder.addFieldOffset(2, entriesOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createEntriesVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (var i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startEntriesVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset recordOffset
         */
        static addRecord(builder: flatbuffers.Builder, recordOffset: flatbuffers.Offset) {
            builder.addFieldOffset(3, recordOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset modeDataOffset
         */
        static addModeData(builder: flatbuffers.Builder, modeDataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(4, modeDataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetLeaderboard(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetLeaderboardEntry {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetLeaderboardEntry
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetLeaderboardEntry {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetLeaderboardEntry= obj
         * @returns NetLeaderboardEntry
         */
        static getRootAsNetLeaderboardEntry(bb: flatbuffers.ByteBuffer, obj?: NetLeaderboardEntry): NetLeaderboardEntry {
            return (obj || new NetLeaderboardEntry()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        fleetID(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        name(): string | null;
        name(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        name(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @returns number
         */
        score(): number {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        color(): string | null;
        color(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        color(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param Game.Engine.Networking.FlatBuffers.Vec2= obj
         * @returns Game.Engine.Networking.FlatBuffers.Vec2|null
         */
        position(obj?: Game.Engine.Networking.FlatBuffers.Vec2): Game.Engine.Networking.FlatBuffers.Vec2 | null {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.Vec2()).__init(this.bb_pos + offset, this.bb!) : null;
        }

        /**
         * @returns boolean
         */
        token(): boolean {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        modeData(): string | null;
        modeData(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        modeData(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetLeaderboardEntry(builder: flatbuffers.Builder) {
            builder.startObject(7);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number fleetID
         */
        static addFleetID(builder: flatbuffers.Builder, fleetID: number) {
            builder.addFieldInt32(0, fleetID, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset nameOffset
         */
        static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, nameOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number score
         */
        static addScore(builder: flatbuffers.Builder, score: number) {
            builder.addFieldInt32(2, score, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset colorOffset
         */
        static addColor(builder: flatbuffers.Builder, colorOffset: flatbuffers.Offset) {
            builder.addFieldOffset(3, colorOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset positionOffset
         */
        static addPosition(builder: flatbuffers.Builder, positionOffset: flatbuffers.Offset) {
            builder.addFieldStruct(4, positionOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean token
         */
        static addToken(builder: flatbuffers.Builder, token: boolean) {
            builder.addFieldInt8(5, +token, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset modeDataOffset
         */
        static addModeData(builder: flatbuffers.Builder, modeDataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(6, modeDataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetLeaderboardEntry(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetSpawn {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetSpawn
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetSpawn {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetSpawn= obj
         * @returns NetSpawn
         */
        static getRootAsNetSpawn(bb: flatbuffers.ByteBuffer, obj?: NetSpawn): NetSpawn {
            return (obj || new NetSpawn()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        name(): string | null;
        name(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        name(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        ship(): string | null;
        ship(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        ship(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        color(): string | null;
        color(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        color(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        token(): string | null;
        token(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        token(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetSpawn(builder: flatbuffers.Builder) {
            builder.startObject(4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset nameOffset
         */
        static addName(builder: flatbuffers.Builder, nameOffset: flatbuffers.Offset) {
            builder.addFieldOffset(0, nameOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset shipOffset
         */
        static addShip(builder: flatbuffers.Builder, shipOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, shipOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset colorOffset
         */
        static addColor(builder: flatbuffers.Builder, colorOffset: flatbuffers.Offset) {
            builder.addFieldOffset(2, colorOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset tokenOffset
         */
        static addToken(builder: flatbuffers.Builder, tokenOffset: flatbuffers.Offset) {
            builder.addFieldOffset(3, tokenOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetSpawn(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetExit {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetExit
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetExit {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetExit= obj
         * @returns NetExit
         */
        static getRootAsNetExit(bb: flatbuffers.ByteBuffer, obj?: NetExit): NetExit {
            return (obj || new NetExit()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        code(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetExit(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number code
         */
        static addCode(builder: flatbuffers.Builder, code: number) {
            builder.addFieldInt32(0, code, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetExit(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetControlInput {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetControlInput
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetControlInput {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetControlInput= obj
         * @returns NetControlInput
         */
        static getRootAsNetControlInput(bb: flatbuffers.ByteBuffer, obj?: NetControlInput): NetControlInput {
            return (obj || new NetControlInput()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        angle(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
        }

        /**
         * @returns number
         */
        x(): number {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
        }

        /**
         * @returns number
         */
        y(): number {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.readFloat32(this.bb_pos + offset) : 0.0;
        }

        /**
         * @returns boolean
         */
        boost(): boolean {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @returns boolean
         */
        shoot(): boolean {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        spectateControl(): string | null;
        spectateControl(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        spectateControl(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        customData(): string | null;
        customData(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        customData(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetControlInput(builder: flatbuffers.Builder) {
            builder.startObject(7);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number angle
         */
        static addAngle(builder: flatbuffers.Builder, angle: number) {
            builder.addFieldFloat32(0, angle, 0.0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number x
         */
        static addX(builder: flatbuffers.Builder, x: number) {
            builder.addFieldFloat32(1, x, 0.0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number y
         */
        static addY(builder: flatbuffers.Builder, y: number) {
            builder.addFieldFloat32(2, y, 0.0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean boost
         */
        static addBoost(builder: flatbuffers.Builder, boost: boolean) {
            builder.addFieldInt8(3, +boost, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean shoot
         */
        static addShoot(builder: flatbuffers.Builder, shoot: boolean) {
            builder.addFieldInt8(4, +shoot, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset spectateControlOffset
         */
        static addSpectateControl(builder: flatbuffers.Builder, spectateControlOffset: flatbuffers.Offset) {
            builder.addFieldOffset(5, spectateControlOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset customDataOffset
         */
        static addCustomData(builder: flatbuffers.Builder, customDataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(6, customDataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetControlInput(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetEvent {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetEvent
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetEvent {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetEvent= obj
         * @returns NetEvent
         */
        static getRootAsNetEvent(bb: flatbuffers.ByteBuffer, obj?: NetEvent): NetEvent {
            return (obj || new NetEvent()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        type(): string | null;
        type(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        type(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        data(): string | null;
        data(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        data(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetEvent(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset typeOffset
         */
        static addType(builder: flatbuffers.Builder, typeOffset: flatbuffers.Offset) {
            builder.addFieldOffset(0, typeOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset dataOffset
         */
        static addData(builder: flatbuffers.Builder, dataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, dataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetEvent(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetPing {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetPing
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetPing {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetPing= obj
         * @returns NetPing
         */
        static getRootAsNetPing(bb: flatbuffers.ByteBuffer, obj?: NetPing): NetPing {
            return (obj || new NetPing()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        time(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        latency(): number {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        fps(): number {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        vps(): number {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        ups(): number {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        cs(): number {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns boolean
         */
        backgrounded(): boolean {
            var offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : false;
        }

        /**
         * @returns number
         */
        bandwidthThrottle(): number {
            var offset = this.bb!.__offset(this.bb_pos, 18);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetPing(builder: flatbuffers.Builder) {
            builder.startObject(8);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number time
         */
        static addTime(builder: flatbuffers.Builder, time: number) {
            builder.addFieldInt32(0, time, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number latency
         */
        static addLatency(builder: flatbuffers.Builder, latency: number) {
            builder.addFieldInt32(1, latency, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number fps
         */
        static addFps(builder: flatbuffers.Builder, fps: number) {
            builder.addFieldInt32(2, fps, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number vps
         */
        static addVps(builder: flatbuffers.Builder, vps: number) {
            builder.addFieldInt32(3, vps, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number ups
         */
        static addUps(builder: flatbuffers.Builder, ups: number) {
            builder.addFieldInt32(4, ups, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number cs
         */
        static addCs(builder: flatbuffers.Builder, cs: number) {
            builder.addFieldInt32(5, cs, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean backgrounded
         */
        static addBackgrounded(builder: flatbuffers.Builder, backgrounded: boolean) {
            builder.addFieldInt8(6, +backgrounded, +false);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number bandwidthThrottle
         */
        static addBandwidthThrottle(builder: flatbuffers.Builder, bandwidthThrottle: number) {
            builder.addFieldInt32(7, bandwidthThrottle, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetPing(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetWorldView {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetWorldView
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetWorldView {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetWorldView= obj
         * @returns NetWorldView
         */
        static getRootAsNetWorldView(bb: flatbuffers.ByteBuffer, obj?: NetWorldView): NetWorldView {
            return (obj || new NetWorldView()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        time(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @param Game.Engine.Networking.FlatBuffers.NetBody= obj
         * @returns Game.Engine.Networking.FlatBuffers.NetBody|null
         */
        camera(obj?: Game.Engine.Networking.FlatBuffers.NetBody): Game.Engine.Networking.FlatBuffers.NetBody | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.NetBody()).__init(this.bb_pos + offset, this.bb!) : null;
        }

        /**
         * @returns number
         */
        fleetID(): number {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns boolean
         */
        isAlive(): boolean {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? !!this.bb!.readInt8(this.bb_pos + offset) : true;
        }

        /**
         * @param number index
         * @param Game.Engine.Networking.FlatBuffers.NetBody= obj
         * @returns Game.Engine.Networking.FlatBuffers.NetBody
         */
        updates(index: number, obj?: Game.Engine.Networking.FlatBuffers.NetBody): Game.Engine.Networking.FlatBuffers.NetBody | null {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.NetBody()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 28, this.bb!) : null;
        }

        /**
         * @returns number
         */
        updatesLength(): number {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param number index
         * @returns number
         */
        deletes(index: number): number | null {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
        }

        /**
         * @returns number
         */
        deletesLength(): number {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @returns Uint32Array
         */
        deletesArray(): Uint32Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
        }

        /**
         * @param number index
         * @param Game.Engine.Networking.FlatBuffers.NetGroup= obj
         * @returns Game.Engine.Networking.FlatBuffers.NetGroup
         */
        groups(index: number, obj?: Game.Engine.Networking.FlatBuffers.NetGroup): Game.Engine.Networking.FlatBuffers.NetGroup | null {
            var offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.NetGroup()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        groupsLength(): number {
            var offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @param number index
         * @returns number
         */
        groupDeletes(index: number): number | null {
            var offset = this.bb!.__offset(this.bb_pos, 18);
            return offset ? this.bb!.readUint32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
        }

        /**
         * @returns number
         */
        groupDeletesLength(): number {
            var offset = this.bb!.__offset(this.bb_pos, 18);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @returns Uint32Array
         */
        groupDeletesArray(): Uint32Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 18);
            return offset ? new Uint32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
        }

        /**
         * @param number index
         * @param Game.Engine.Networking.FlatBuffers.NetAnnouncement= obj
         * @returns Game.Engine.Networking.FlatBuffers.NetAnnouncement
         */
        announcements(index: number, obj?: Game.Engine.Networking.FlatBuffers.NetAnnouncement): Game.Engine.Networking.FlatBuffers.NetAnnouncement | null {
            var offset = this.bb!.__offset(this.bb_pos, 20);
            return offset ? (obj || new Game.Engine.Networking.FlatBuffers.NetAnnouncement()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
        }

        /**
         * @returns number
         */
        announcementsLength(): number {
            var offset = this.bb!.__offset(this.bb_pos, 20);
            return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        playerCount(): number {
            var offset = this.bb!.__offset(this.bb_pos, 22);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        spectatorCount(): number {
            var offset = this.bb!.__offset(this.bb_pos, 24);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        cooldownShoot(): number {
            var offset = this.bb!.__offset(this.bb_pos, 26);
            return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        cooldownBoost(): number {
            var offset = this.bb!.__offset(this.bb_pos, 28);
            return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        worldSize(): number {
            var offset = this.bb!.__offset(this.bb_pos, 30);
            return offset ? this.bb!.readUint16(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        customData(): string | null;
        customData(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        customData(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 32);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetWorldView(builder: flatbuffers.Builder) {
            builder.startObject(15);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number time
         */
        static addTime(builder: flatbuffers.Builder, time: number) {
            builder.addFieldInt32(0, time, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset cameraOffset
         */
        static addCamera(builder: flatbuffers.Builder, cameraOffset: flatbuffers.Offset) {
            builder.addFieldStruct(1, cameraOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number fleetID
         */
        static addFleetID(builder: flatbuffers.Builder, fleetID: number) {
            builder.addFieldInt32(2, fleetID, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param boolean isAlive
         */
        static addIsAlive(builder: flatbuffers.Builder, isAlive: boolean) {
            builder.addFieldInt8(3, +isAlive, +true);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset updatesOffset
         */
        static addUpdates(builder: flatbuffers.Builder, updatesOffset: flatbuffers.Offset) {
            builder.addFieldOffset(4, updatesOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startUpdatesVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(28, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset deletesOffset
         */
        static addDeletes(builder: flatbuffers.Builder, deletesOffset: flatbuffers.Offset) {
            builder.addFieldOffset(5, deletesOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<number> data
         * @returns flatbuffers.Offset
         */
        static createDeletesVector(builder: flatbuffers.Builder, data: number[] | Uint8Array): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (var i = data.length - 1; i >= 0; i--) {
                builder.addInt32(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startDeletesVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset groupsOffset
         */
        static addGroups(builder: flatbuffers.Builder, groupsOffset: flatbuffers.Offset) {
            builder.addFieldOffset(6, groupsOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createGroupsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (var i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startGroupsVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset groupDeletesOffset
         */
        static addGroupDeletes(builder: flatbuffers.Builder, groupDeletesOffset: flatbuffers.Offset) {
            builder.addFieldOffset(7, groupDeletesOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<number> data
         * @returns flatbuffers.Offset
         */
        static createGroupDeletesVector(builder: flatbuffers.Builder, data: number[] | Uint8Array): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (var i = data.length - 1; i >= 0; i--) {
                builder.addInt32(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startGroupDeletesVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset announcementsOffset
         */
        static addAnnouncements(builder: flatbuffers.Builder, announcementsOffset: flatbuffers.Offset) {
            builder.addFieldOffset(8, announcementsOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Array.<flatbuffers.Offset> data
         * @returns flatbuffers.Offset
         */
        static createAnnouncementsVector(builder: flatbuffers.Builder, data: flatbuffers.Offset[]): flatbuffers.Offset {
            builder.startVector(4, data.length, 4);
            for (var i = data.length - 1; i >= 0; i--) {
                builder.addOffset(data[i]);
            }
            return builder.endVector();
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number numElems
         */
        static startAnnouncementsVector(builder: flatbuffers.Builder, numElems: number) {
            builder.startVector(4, numElems, 4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number playerCount
         */
        static addPlayerCount(builder: flatbuffers.Builder, playerCount: number) {
            builder.addFieldInt32(9, playerCount, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number spectatorCount
         */
        static addSpectatorCount(builder: flatbuffers.Builder, spectatorCount: number) {
            builder.addFieldInt32(10, spectatorCount, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number cooldownShoot
         */
        static addCooldownShoot(builder: flatbuffers.Builder, cooldownShoot: number) {
            builder.addFieldInt8(11, cooldownShoot, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number cooldownBoost
         */
        static addCooldownBoost(builder: flatbuffers.Builder, cooldownBoost: number) {
            builder.addFieldInt8(12, cooldownBoost, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number worldSize
         */
        static addWorldSize(builder: flatbuffers.Builder, worldSize: number) {
            builder.addFieldInt16(13, worldSize, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset customDataOffset
         */
        static addCustomData(builder: flatbuffers.Builder, customDataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(14, customDataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetWorldView(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetGroup {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetGroup
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetGroup {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetGroup= obj
         * @returns NetGroup
         */
        static getRootAsNetGroup(bb: flatbuffers.ByteBuffer, obj?: NetGroup): NetGroup {
            return (obj || new NetGroup()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns number
         */
        group(): number {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        type(): number {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.readUint8(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        caption(): string | null;
        caption(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        caption(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @returns number
         */
        zindex(): number {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @returns number
         */
        owner(): number {
            var offset = this.bb!.__offset(this.bb_pos, 12);
            return offset ? this.bb!.readUint32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        color(): string | null;
        color(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        color(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 14);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        customData(): string | null;
        customData(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        customData(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 16);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetGroup(builder: flatbuffers.Builder) {
            builder.startObject(7);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number group
         */
        static addGroup(builder: flatbuffers.Builder, group: number) {
            builder.addFieldInt32(0, group, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number type
         */
        static addType(builder: flatbuffers.Builder, type: number) {
            builder.addFieldInt8(1, type, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset captionOffset
         */
        static addCaption(builder: flatbuffers.Builder, captionOffset: flatbuffers.Offset) {
            builder.addFieldOffset(2, captionOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number zindex
         */
        static addZindex(builder: flatbuffers.Builder, zindex: number) {
            builder.addFieldInt32(3, zindex, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number owner
         */
        static addOwner(builder: flatbuffers.Builder, owner: number) {
            builder.addFieldInt32(4, owner, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset colorOffset
         */
        static addColor(builder: flatbuffers.Builder, colorOffset: flatbuffers.Offset) {
            builder.addFieldOffset(5, colorOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset customDataOffset
         */
        static addCustomData(builder: flatbuffers.Builder, customDataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(6, customDataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetGroup(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class Vec2 {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns Vec2
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): Vec2 {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @returns number
         */
        x(): number {
            return this.bb!.readInt16(this.bb_pos);
        }

        /**
         * @returns number
         */
        y(): number {
            return this.bb!.readInt16(this.bb_pos + 2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number x
         * @param number y
         * @returns flatbuffers.Offset
         */
        static createVec2(builder: flatbuffers.Builder, x: number, y: number): flatbuffers.Offset {
            builder.prep(2, 4);
            builder.writeInt16(y);
            builder.writeInt16(x);
            return builder.offset();
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetBody {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetBody
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetBody {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @returns number
         */
        id(): number {
            return this.bb!.readUint32(this.bb_pos);
        }

        /**
         * @returns number
         */
        definitionTime(): number {
            return this.bb!.readUint32(this.bb_pos + 4);
        }

        /**
         * @param Game.Engine.Networking.FlatBuffers.Vec2= obj
         * @returns Game.Engine.Networking.FlatBuffers.Vec2|null
         */
        originalPosition(obj?: Game.Engine.Networking.FlatBuffers.Vec2): Game.Engine.Networking.FlatBuffers.Vec2 | null {
            return (obj || new Game.Engine.Networking.FlatBuffers.Vec2()).__init(this.bb_pos + 8, this.bb!);
        }

        /**
         * @param Game.Engine.Networking.FlatBuffers.Vec2= obj
         * @returns Game.Engine.Networking.FlatBuffers.Vec2|null
         */
        velocity(obj?: Game.Engine.Networking.FlatBuffers.Vec2): Game.Engine.Networking.FlatBuffers.Vec2 | null {
            return (obj || new Game.Engine.Networking.FlatBuffers.Vec2()).__init(this.bb_pos + 12, this.bb!);
        }

        /**
         * @returns number
         */
        originalAngle(): number {
            return this.bb!.readInt8(this.bb_pos + 16);
        }

        /**
         * @returns number
         */
        angularVelocity(): number {
            return this.bb!.readInt8(this.bb_pos + 17);
        }

        /**
         * @returns number
         */
        size(): number {
            return this.bb!.readUint8(this.bb_pos + 18);
        }

        /**
         * @returns number
         */
        sprite(): number {
            return this.bb!.readUint16(this.bb_pos + 20);
        }

        /**
         * @returns number
         */
        mode(): number {
            return this.bb!.readUint8(this.bb_pos + 22);
        }

        /**
         * @returns number
         */
        group(): number {
            return this.bb!.readUint32(this.bb_pos + 24);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number id
         * @param number definitionTime
         * @param number originalPosition_x
         * @param number originalPosition_y
         * @param number velocity_x
         * @param number velocity_y
         * @param number originalAngle
         * @param number angularVelocity
         * @param number size
         * @param number sprite
         * @param number mode
         * @param number group
         * @returns flatbuffers.Offset
         */
        static createNetBody(
            builder: flatbuffers.Builder,
            id: number,
            definitionTime: number,
            originalPosition_x: number,
            originalPosition_y: number,
            velocity_x: number,
            velocity_y: number,
            originalAngle: number,
            angularVelocity: number,
            size: number,
            sprite: number,
            mode: number,
            group: number
        ): flatbuffers.Offset {
            builder.prep(4, 28);
            builder.writeInt32(group);
            builder.pad(1);
            builder.writeInt8(mode);
            builder.writeInt16(sprite);
            builder.pad(1);
            builder.writeInt8(size);
            builder.writeInt8(angularVelocity);
            builder.writeInt8(originalAngle);
            builder.prep(2, 4);
            builder.writeInt16(velocity_y);
            builder.writeInt16(velocity_x);
            builder.prep(2, 4);
            builder.writeInt16(originalPosition_y);
            builder.writeInt16(originalPosition_x);
            builder.writeInt32(definitionTime);
            builder.writeInt32(id);
            return builder.offset();
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetAnnouncement {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetAnnouncement
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetAnnouncement {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetAnnouncement= obj
         * @returns NetAnnouncement
         */
        static getRootAsNetAnnouncement(bb: flatbuffers.ByteBuffer, obj?: NetAnnouncement): NetAnnouncement {
            return (obj || new NetAnnouncement()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        text(): string | null;
        text(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        text(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        type(): string | null;
        type(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        type(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @returns number
         */
        pointsDelta(): number {
            var offset = this.bb!.__offset(this.bb_pos, 8);
            return offset ? this.bb!.readInt32(this.bb_pos + offset) : 0;
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        extraData(): string | null;
        extraData(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        extraData(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 10);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetAnnouncement(builder: flatbuffers.Builder) {
            builder.startObject(4);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset textOffset
         */
        static addText(builder: flatbuffers.Builder, textOffset: flatbuffers.Offset) {
            builder.addFieldOffset(0, textOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset typeOffset
         */
        static addType(builder: flatbuffers.Builder, typeOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, typeOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param number pointsDelta
         */
        static addPointsDelta(builder: flatbuffers.Builder, pointsDelta: number) {
            builder.addFieldInt32(2, pointsDelta, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset extraDataOffset
         */
        static addExtraData(builder: flatbuffers.Builder, extraDataOffset: flatbuffers.Offset) {
            builder.addFieldOffset(3, extraDataOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetAnnouncement(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetAuthenticate {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetAuthenticate
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetAuthenticate {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetAuthenticate= obj
         * @returns NetAuthenticate
         */
        static getRootAsNetAuthenticate(bb: flatbuffers.ByteBuffer, obj?: NetAuthenticate): NetAuthenticate {
            return (obj || new NetAuthenticate()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @param flatbuffers.Encoding= optionalEncoding
         * @returns string|Uint8Array|null
         */
        token(): string | null;
        token(optionalEncoding: flatbuffers.Encoding): string | Uint8Array | null;
        token(optionalEncoding?: any): string | Uint8Array | null {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetAuthenticate(builder: flatbuffers.Builder) {
            builder.startObject(1);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset tokenOffset
         */
        static addToken(builder: flatbuffers.Builder, tokenOffset: flatbuffers.Offset) {
            builder.addFieldOffset(0, tokenOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetAuthenticate(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }
    }
}
/**
 * @constructor
 */
export namespace Game.Engine.Networking.FlatBuffers {
    export class NetQuantum {
        bb: flatbuffers.ByteBuffer | null = null;

        bb_pos: number = 0;
        /**
         * @param number i
         * @param flatbuffers.ByteBuffer bb
         * @returns NetQuantum
         */
        __init(i: number, bb: flatbuffers.ByteBuffer): NetQuantum {
            this.bb_pos = i;
            this.bb = bb;
            return this;
        }

        /**
         * @param flatbuffers.ByteBuffer bb
         * @param NetQuantum= obj
         * @returns NetQuantum
         */
        static getRootAsNetQuantum(bb: flatbuffers.ByteBuffer, obj?: NetQuantum): NetQuantum {
            return (obj || new NetQuantum()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
        }

        /**
         * @returns Game.Engine.Networking.FlatBuffers.AllMessages
         */
        messageType(): Game.Engine.Networking.FlatBuffers.AllMessages {
            var offset = this.bb!.__offset(this.bb_pos, 4);
            return offset ? /**  */ this.bb!.readUint8(this.bb_pos + offset) : Game.Engine.Networking.FlatBuffers.AllMessages.NONE;
        }

        /**
         * @param flatbuffers.Table obj
         * @returns ?flatbuffers.Table
         */
        message<T extends flatbuffers.Table>(obj: T): T | null {
            var offset = this.bb!.__offset(this.bb_pos, 6);
            return offset ? this.bb!.__union(obj, this.bb_pos + offset) : null;
        }

        /**
         * @param flatbuffers.Builder builder
         */
        static startNetQuantum(builder: flatbuffers.Builder) {
            builder.startObject(2);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param Game.Engine.Networking.FlatBuffers.AllMessages messageType
         */
        static addMessageType(builder: flatbuffers.Builder, messageType: Game.Engine.Networking.FlatBuffers.AllMessages) {
            builder.addFieldInt8(0, messageType, Game.Engine.Networking.FlatBuffers.AllMessages.NONE);
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset messageOffset
         */
        static addMessage(builder: flatbuffers.Builder, messageOffset: flatbuffers.Offset) {
            builder.addFieldOffset(1, messageOffset, 0);
        }

        /**
         * @param flatbuffers.Builder builder
         * @returns flatbuffers.Offset
         */
        static endNetQuantum(builder: flatbuffers.Builder): flatbuffers.Offset {
            var offset = builder.endObject();
            return offset;
        }

        /**
         * @param flatbuffers.Builder builder
         * @param flatbuffers.Offset offset
         */
        static finishNetQuantumBuffer(builder: flatbuffers.Builder, offset: flatbuffers.Offset) {
            builder.finish(offset);
        }
    }
}
