export const spriteIndices = [
    "none",
    "ship0",
    "ship_green",
    "ship_gray",
    "ship_orange",
    "ship_pink",
    "ship_red",
    "ship_cyan",
    "ship_blue",
    "ship_yellow",
    "ship_ab_green",
    "ship_ab_orange",
    "ship_ab_pink",
    "ship_ab_red",
    "ship_ab_cyan",
    "ship_ab_blue",
    "ship_ab_yellow",
    "ship_flash",
    "ship_secret",
    "ship_zed",
    "bullet_green",
    "bullet_orange",
    "bullet_pink",
    "bullet_red",
    "bullet_cyan",
    "bullet_blue",
    "bullet_yellow",
    "fish",
    "fish_green",
    "fish_orange",
    "fish_pink",
    "fish_red",
    "fish_cyan",
    "fish_blue",
    "fish_yellow",
    "bullet",
    "seeker",
    "seeker_pickup",
    "obstacle",
    "arrow",
    "ctf_flag_blue",
    "ctf_flag_red",
    "ctf_base",
    "ctf_score_final",
    "ctf_score_final_blue",
    "ctf_score_final_red",
    "ctf_score_left_0",
    "ctf_score_left_1",
    "ctf_score_left_2",
    "ctf_score_left_3",
    "ctf_score_left_4",
    "ctf_score_right_0",
    "ctf_score_right_1",
    "ctf_score_right_2",
    "ctf_score_right_3",
    "ctf_score_right_4",
    "ctf_score_stripes",
    "ctf_arrow_red",
    "ctf_arrow_blue",
    "ctf_arrow_trans_flag",
    "thruster_green",
    "thruster_orange",
    "thruster_pink",
    "thruster_red",
    "thruster_cyan",
    "thruster_blue",
    "thruster_yellow",
    "circles",
    "shield",
    "shield_pickup",
    "wormhole",
    "boom"
];
